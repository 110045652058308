import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Sitemark from './SitemarkIcon';
import ColorModeIconDropdown from '../shared-theme/ColorModeIconDropdown';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailCenter from '@mui/icons-material/Mail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import { Stack } from '@mui/material';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 'calc(var(--template-frame-height, 0px) + 28px)',
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <Sitemark />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button variant="text" color="info" size="small" href="#Beneficios">
              Beneficios
              </Button>
              <Button variant="text" color="info" size="small" href="#Caracteristicas">
              Características
              </Button>
              <Button variant="text" color="info" size="small" href="#Soluciones">
              Soluciones
              </Button>
              <Button variant="text" color="info" size="small" href="#Contacto">
              Contacto
              </Button>              
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: 'left', color: 'text.secondary' }}
        >
          <IconButton
            color="inherit"
            size="small"
            href="https://api.whatsapp.com/send/?phone=573108807815&text=Buen%20día!%20Vengo%20desde%20la%20pagina%20de%20Skala,%20estoy%20interesado%20en%20..."
            aria-label="WhatsApp"
            sx={{ alignSelf: 'center' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="tel:+573108807815"
            aria-label="Telefono"
            sx={{ alignSelf: 'center' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PhoneIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.linkedin.com/company/skalaredlogistica/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="mailto:leonardo.moyano@skalaredlogistica.com"
            aria-label="Correo Electronico"
            sx={{ alignSelf: 'center' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <MailCenter />
          </IconButton>
        </Stack>
            {/* <ColorModeIconDropdown /> */}
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1 }}>            
            <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="top"
              open={open}
              onClose={toggleDrawer(false)}
              PaperProps={{
                sx: {
                  top: 'var(--template-frame-height, 0px)',
                },
              }}
            >
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>

                <MenuItem component="a" href="#Beneficios" onClick={toggleDrawer(false)}>
                  Beneficios
                </MenuItem>
                <MenuItem component="a" href="#Caracteristicas" onClick={toggleDrawer(false)}>
                  Características
                </MenuItem>
                <MenuItem component="a" href="#Soluciones" onClick={toggleDrawer(false)}>
                  Soluciones
                </MenuItem>
                <MenuItem component="a" href="#Contacto" onClick={toggleDrawer(false)}>
                  Contacto
                </MenuItem>
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
