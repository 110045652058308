import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const tiers = [
  {
    title: 'Generadores de Carga',
    description: [
      'Optimización de la Planificación: Facilita la programación y gestión de cargas, asegurando que cada envío sea eficiente y se adapte a las necesidades del cliente.',
      'Visibilidad Completa: Mantén un control total sobre tus envíos, con actualizaciones en tiempo real sobre el estado de la carga y su ubicación.',
      'Reducción de Costos: Al conectar directamente con transportadoras y transportistas, puedes optimizar los costos de transporte y reducir tiempos muertos.',
    ],
    buttonText: 'Más Información',
    buttonVariant: 'outlined',
    buttonColor: 'primary',
    buttonLink: 'https://api.whatsapp.com/send/?phone=573108807815&text=Buen%20día!%20Vengo%20desde%20la%20pagina%20de%20Skala,%20estoy%20interesad@%20en%20ser%20generador%20de%20carga',
  },
  {
    title: 'Transportadoras',
    price: '15',
    description: [
      'Gestión Integral de Flotas: Administra múltiples vehículos y conductores desde una plataforma centralizada, optimizando la asignación de recursos y la programación de viajes.',
      'Documentación Automatizada: Reduce la carga administrativa con documentación digital, incluyendo manifiestos y registros, para una gestión eficiente y sin errores.',
      'Cumplimiento y Regulación: Asegura que todos los vehículos y conductores cumplen con las regulaciones vigentes, mejorando la seguridad y el cumplimiento normativo.',
    ],
    buttonText: 'Contacto',
    buttonVariant: 'contained',
    buttonColor: 'secondary',
    buttonLink: 'https://api.whatsapp.com/send/?phone=573108807815&text=Buen%20día!%20Vengo%20desde%20la%20pagina%20de%20Skala,%20estoy%20interesad@%20en%20ser%20transportador',
  },
  {
    title: 'Transportistas',
    price: '30',
    description: [
      'Asignación Óptima de Viajes: Recibe asignaciones de viajes basadas en la ubicación y disponibilidad, maximizando las oportunidades de trabajo y minimizando los tiempos muertos.',
      'Acceso a Información Detallada: Toda la información del viaje, como rutas y requerimientos, está disponible de manera clara y accesible desde la plataforma.',
      'Pagos y Facturación Simplificados: Gestiona tus pagos y facturación de manera digital y sin complicaciones, asegurando un proceso transparente y rápido.',
    ],
    buttonText: 'Trabaja con nosotros',
    buttonVariant: 'outlined',
    buttonColor: 'primary',
    buttonLink: 'https://api.whatsapp.com/send/?phone=573108807815&text=Buen%20día!%20Vengo%20desde%20la%20pagina%20de%20Skala,%20estoy%20interesad@%20en%20ser%20transportista',
  },
];

export default function Pricing() {
  return (
    <Container
      id="Soluciones"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Soluciones Personalizadas para Todos los Actores de la Cadena Logística
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        {tiers.map((tier) => (
          <Grid
            size={{ xs: 12, sm: tier.title === 'Enterprise' ? 12 : 6, md: 4 }}
            key={tier.title}
          >
            <Card
              sx={[
                {
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                },
                tier.title === 'Professional' &&
                  ((theme) => ({
                    border: 'none',
                    background:
                      'radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))',
                    boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                    ...theme.applyStyles('dark', {
                      background:
                        'radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))',
                      boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                    }),
                  })),
              ]}
            >
              <CardContent>
                <Box
                  sx={[
                    {
                      mb: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 2,
                    },
                    tier.title === 'Professional'
                      ? { color: 'grey.100' }
                      : { color: '' },
                  ]}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                </Box>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}
                  >
                    <CheckCircleRoundedIcon
                      sx={[
                        {
                          width: 20,
                        },
                        tier.title === 'Professional'
                          ? { color: 'primary.light' }
                          : { color: 'primary.main' },
                      ]}
                    />
                    <Typography
                      variant="subtitle2"
                      component={'span'}
                      sx={[
                        tier.title === 'Professional'
                          ? { color: 'grey.50' }
                          : { color: null },
                      ]}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant as 'outlined' | 'contained'}
                  color={tier.buttonColor as 'primary' | 'secondary'}
                  href={tier.buttonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
