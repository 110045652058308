import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RouteIcon  from '@mui/icons-material/Route';
import CloudRoundedIcon from '@mui/icons-material/CloudRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import IntegrationInstructionsRoundedIcon from '@mui/icons-material/IntegrationInstructionsRounded';
import GroupRoundedIcon  from '@mui/icons-material/GroupRounded';

const items = [
  {
    icon: <RouteIcon  />,
    title: 'Gestión Eficiente de Viajes',
    description:
      'Organiza rutas de transporte de manera óptima, asigna conductores y vehículos automáticamente, y realiza ajustes según las necesidades en tiempo real. Todo para asegurar un uso eficiente de los recursos.',
  },
  {
    icon: <CloudRoundedIcon />,
    title: 'Documentación Digital y Sin Papel',
    description:
      'Despídete de la gestión manual de papeles. Con Skala, toda la documentación es digital, segura y fácil de compartir, mejorando la eficiencia y reduciendo errores administrativos.',
  },
  {
    icon: <VisibilityRoundedIcon />,
    title: 'Visibilidad y Control Total en Tiempo Real',
    description:
      'Monitorea el estado de cada vehículo, identifica demoras y novedades al instante. Con información en tiempo real, tienes el control total de tus operaciones, asegurando la puntualidad de cada entrega.',
  },
  {
    icon: <InsightsRoundedIcon />,
    title: 'Análisis de Datos',
    description:
      'Utiliza datos detallados para tomar decisiones informadas. Nuestras herramientas de IA te permiten optimizar tiempos, mejorar rutas y reducir costos operativos, llevando tu logística al siguiente nivel.',
  },
  {
    icon: <IntegrationInstructionsRoundedIcon />,
    title: 'Integración sin Problemas',
    description:
      'Integra Skala fácilmente con tu sistema actual. Nuestra plataforma se adapta a tus necesidades, conectando con sistemas ERP y otras herramientas clave para garantizar una transición fluida.',
  },
  {
    icon: <GroupRoundedIcon  />,
    title: 'Colaboración Efectiva',
    description:
      'Facilita la colaboración entre generadores de carga, transportadoras y transportistas para hacer cada viaje más eficiente.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="Caracteristicas"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
          Características 
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          En Skala Red Logística ofrecemos un conjunto de características diseñadas para transformar la forma en la que gestionas tus operaciones logísticas:
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
